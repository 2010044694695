import React, { useState, useEffect, useCallback } from 'react';
import ColorThief from 'colorthief';

const defaultNumColors = 8;

const ColorExtractor = () => {
  const [image, setImage] = useState(null);
  const [colors, setColors] = useState([]);
  const [numColors, setNumColors] = useState(defaultNumColors);
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const clearImage = () => {
    setImage(null);
    setColors([]);
    setFileInputKey(Date.now()); // Reset the file input
    setNumColors(defaultNumColors);
  };

  const extractColors = useCallback(() => {
    const img = new Image();
    img.src = image;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const colorThief = new ColorThief();
      setColors(colorThief.getPalette(img, numColors));
    };
  }, [image, numColors]);

  const rgbToHex = (rgb) => {
    return '#' + rgb.map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('');
  };

  const copyToClipboard = (str) => {
    navigator.clipboard.writeText(str).then(() => {
      // alert(`Copied: ${str}`);
    }, () => {
      alert('Failed to copy');
    });
  };

  const incrementColors = () => {
    // Max 20 colors
    if (numColors >= 20) return;
    setNumColors(prevNumColors => prevNumColors + 1);
  };

  const decrementColors = () => {
    // Min 2 colors
    if (numColors <= 2) return;
    setNumColors(prevNumColors => Math.max(prevNumColors - 1, 1));
  };

  useEffect(() => {
    extractColors();
  }, [numColors, image, extractColors]);

  return (
    <div>
      <h1 className="text-3xl font-bold text-white text-center mb-5">
        Color Extractor
      </h1>
      <div className="text-center my-4">
        <label
          htmlFor="image-upload"
          className="cursor-pointer py-2 px-4 bg-blue-500 text-white rounded-lg shadow-lg inline-block"
        >
          Select Image
        </label>
        <input
          id="image-upload"
          type="file"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          key={fileInputKey}
        />
        <button
          onClick={clearImage}
          className="ml-4 py-2 px-4 bg-red-500 text-white rounded-lg shadow-lg"
        >
          Clear
        </button>
      </div>
      {
        image ? (
          <div className='flex justify-center items-center flex-col'>
            <img src={image} alt="Selected" className='w-full rounded-xl drop-shadow-lg' crossOrigin="anonymous" onLoad={extractColors} />
            <div className='flex items-center justify-center my-5 flex-col'>
              <p className='text-white mb-2'>Number of colors to extract:</p>
              <div className='flex items-center'>
                <button
                  className={`py-2 px-4 bg-green-500 text-white rounded-lg shadow-lg ${numColors === 2 ? 'opacity-50 cursor-not-allowed' : ''} transition-all`}
                  onClick={decrementColors}
                >-</button>
                <span className='text-white min-w-14 text-center'>{numColors}</span>
                <button
                  className={`py-2 px-4 bg-green-500 text-white rounded-lg shadow-lg ${numColors === 20 ? 'opacity-50 cursor-not-allowed' : ''} transition-all`}
                  onClick={incrementColors}
                >+</button>
              </div>
            </div>
          </div>
        ) : (
          <p className='text-white text-center bg-slate-700 py-9 rounded-xl drop-shadow-lg'>No image selected</p>
        )
      }
      <div className='grid grid-cols-3 sm:grid-cols-4 gap-4'>
        {colors.map((color, index) => (
          <div
            key={index}
            className='rounded-lg drop-shadow-lg text-xs text-white px-2 py-1 border border-black hover:border-white transition-all'
            style={{ backgroundColor: `rgb(${color.join(',')})`, textShadow: `0px 0px 2px rgba(0,0,0,1)` }}
          >
            <div className='text-[10px] font-bold'>
              <p>RGB: rgb({color.join(',')})</p>
              <p>HEX: {rgbToHex(color)}</p>
            </div>
            <div className='flex flex-col gap-1 mt-3'>
              <button
                onClick={() => copyToClipboard(`rgb(${color.join(',')})`)}
                className='bg-slate-900 uppercase font-semibold text-[9px] px-2 py-1 rounded-lg drop-shadow-sm hover:bg-slate-800 transition-all'
              >
                Copy RGB
              </button>
              <button
                onClick={() => copyToClipboard(rgbToHex(color))}
                className='bg-slate-900 uppercase font-semibold text-[9px] px-2 py-1 rounded-lg drop-shadow-sm hover:bg-slate-800 transition-all'
              >
                Copy HEX
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorExtractor;
