import React from 'react';
import { FaHeart } from "react-icons/fa";

const DonateButton = () => {
  return (
    <div className='flex justify-center items-center mt-10 flex-col text-white'>
      <h1 className='text-sm font-bold mb-4 flex items-center'>Made with <FaHeart className='mx-2 text-red-500' /> by RGBskills</h1>
      <form action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="hosted_button_id" value="3DASEQSHYEG8W" />
        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
      </form>
    </div>
  );
};

export default DonateButton;
